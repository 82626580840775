<template>
  <div class="plocydetail">
    <p class="plocydetail_title">{{dataList.title}}</p>
    <p class="plocydetail_time">{{dataList.releaseDate}}</p>
    <img
      :src="dataList.mainImgUrl"
      alt
    />
    <div class="plocydetail_content" v-html="dataList.content">
    
    </div>
    <!-- <div class="plocydetail_down">
      <p class="more_plocy">更多动态</p>
      <div class="more_plocy_content flex_around">
        <news-item v-for="(option,index) in dataList" :key="index" :option="option"></news-item>
      </div>
    </div> -->
  </div>
</template>
<script>
// import newsItem from "./newsitem";
export default {
  data() {
    return {
      dataList: [
      ]
    };
  },
  // components: {
  //   newsItem
  // },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail(){
      const api = this.$fetchApi.dynamicNewsDetail.api + '/' + this.$route.query.id;
      const data = await this.$fetchData.fetchPost({ id: this.$route.query.id }, api);
      if (data.code === '200') {
        this.dataList = data.result;
        console.log(this.dataList);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.plocydetail {
  width: 1010px;
  margin-bottom:100px;
  .plocydetail_title{
    color: #265ae1;
    font-size: 30px;
    text-align: center;
    line-height: 100px;
    background-color: #fff;
  }
  .plocydetail_time{
    color: #666666;
    font-size: 20px;
    text-align: center;
    background-color: #fff;
  }
  img {
    width: 488px;
    height: 264px;
    display: block;
    margin: 20px auto;
  }
  .plocydetail_content {
    text-indent: 2em;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 25px;
    letter-spacing: 0px;
    color: #999999;
  }
  .plocydetail_down {
    width: 100%;
    // background-color: #efefef;
    .more_plocy {
      position: relative;
      width: 229px;
      height: 100px;
      font-family: SourceHanSansCN-Bold;
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 100px;
      text-align: center;
      color: #265ae0;
      margin: 0 auto;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 25px;
        height: 0;
        content: "";
        display: block;
        border: 1px solid #265ae0;
      }
      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        width: 25px;
        height: 0;
        content: "";
        display: block;
        border: 1px solid #265ae0;
      }
    }
  }
}
</style>